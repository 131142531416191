
import { defineComponent, onMounted, reactive } from "vue";
import signatures from "@/views/still/bord/signatures.vue";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Overview",
  components: {
    signatures
  },
  setup() {
    const state = reactive({
      loaderEnabled: true,
      myPDF : "" as any,
    });

    onMounted(async () => {
      //
    });

    return {
      state,
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
